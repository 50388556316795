import React from 'react'

export default function Js() {
    return (
        <React.Fragment >
            <svg version="1.1" className="fill-current h-16 w-16 sm:h-24 sm:w-24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 100 100" >
            <path id="XMLID_456_" d="M14.6,84c2.2,0.9,5.1,1.7,7.9,1.7c10.8,0,17.5-7.6,17.5-20.9c0.1,0.1,13.8,12.9,13.8,12.9
                c5.5,4.7,12.7,7.8,20.1,7.8c12.4,0,21.2-7.3,21.2-19.2c0.1-24-30.3-16.2-30.3-30.8c0-4.5,3.6-7,9.2-7c4.3,0,10.4,1.8,16.5,6.2
                l3.9-9.9C89.2,20.8,82.1,18,74.7,18c-12.2,0-20.5,7.1-20.5,18.6c0,23.7,30.4,15.4,30.4,30.3c0,5.1-3.9,8.1-10.5,8.1
                c-4.7,0-10.1-2.1-15-6L45.9,56.7c0,0-3.7-3.3-5.9-4.7V18.5H11v10.1h19.5v19.3c-2.2-0.5-4.4-0.8-6.7-0.8c-12.4,0-21.2,7.3-21.2,19.2
                C2.5,76.7,12.4,83.1,14.6,84z M23.6,57.6c2.2,0,4.5,0.5,6.9,1.3v5.3c0,6.9-6,12.1-10.7,10.2c-4.1-1.7-6.7-4.2-6.7-8.7
                C13,60.6,17,57.6,23.6,57.6z"/>
            </svg>
        </React.Fragment>
    )
}
