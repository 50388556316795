export const translations = {
    swe: {
        whoAmI: "Vem är jag?",
        description: "Civilingenjör med intresse för energi, klimat, teknik och hållbarhet, samt hur man kan använda detta för att göra världen till en lite bättre plats. Jag jobbar just nu som affärsutvecklare på Ecoclime.",
        sendEmail: "Skicka ett mail",
        sendEmailTooltip: "Du når mig på hej@jakobskarin.se",
        visitLinkedIn: "Besök min LinkedIn",
        visitLinkedInTooltip: "Öppnas i ny flik"
    },
    eng: {
        whoAmI: "Who am I?",
        description: "Master of Science in Engineering with an interest for energy, climate, technology, sustainability and how to use this to make the world just a little bit better. I'm currently working as a Business developer at Ecoclime.",
        sendEmail: "Send me an e-mail",
        sendEmailTooltip: "You can reach me at hej@jakobskarin.se",
        visitLinkedIn: "Visit me on LinkedIn",
        visitLinkedInTooltip: "Opens in a new tab"
    }
}

export const LANGUAGES = {
    swedish: "swe",
    english: "eng"
}

export const DEFAULT_LANGUAGE = LANGUAGES.swedish;

export const mainColor = "teal-500"