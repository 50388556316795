import React, { Component } from 'react'
import ReactTooltip from "react-tooltip"


const availableColorSchemes = {
  teal: {
    standard: {
      bgColor: "teal-500",
      borderColor: "teal-500",
      textColor: "white",
    },
    hover: {
      bgColor: "teal-400",
      borderColor: "teal-400",
      textColor: "white",
    }
  },
  indigo: {
    standard: {
      bgColor: "indigo-500",
      borderColor: "indigo-500",
      textColor: "white",
    },
    hover: {
      bgColor: "indigo-400",
      borderColor: "indigo-400",
      textColor: "white",
    }
  },
  blue: {
    standard: {
      bgColor: "blue-500",
      borderColor: "blue-500",
      textColor: "white",
    },
    hover: {
      bgColor: "blue-400",
      borderColor: "blue-400",
      textColor: "white",
    }
  },
  white: {
    standard: {
      bgColor: "gray-200",
      borderColor: "gray-200",
      textColor: "gray-800",
    },
    hover: {
      bgColor: "gray-300",
      borderColor: "gray-300",
      textColor: "gray-800",
    }
  }
};

export default class LinkButton extends Component {

  render() {

    const { color, large } = this.props;

    const colorSchemes = {
      primary: availableColorSchemes.indigo,
      secondary: availableColorSchemes.blue,
      white: availableColorSchemes.white
    }

    let paddings;

    if (large) {
      paddings = "px-4 py-2"
    }
    else {
      paddings = "px-2"
    }

    return (
      <React.Fragment>
        <a data-tip={this.props.tooltip} className={`rounded-lg ${paddings} cursor-pointer shadow-md
        bg-${colorSchemes[color].standard.bgColor}
        hover:bg-${colorSchemes[color].hover.bgColor}
        border-2 border-${colorSchemes[color].standard.borderColor}
        hover:border-${colorSchemes[color].hover.borderColor}
        text-${colorSchemes[color].standard.textColor}
        hover:text-${colorSchemes[color].hover.textColor}
        ${this.props.className}
        `}
          href={this.props.href}
          target={this.props.blank ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          {this.props.children}
        </a>
        <ReactTooltip />
      </React.Fragment>
    )
  }
}
